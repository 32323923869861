<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <v-row>

      <!-- BUTTONS FOR CREATION -->
      <v-col cols="12" class="d-flex justify-end">
        <v-btn depressed @click="showCrudDialog()" color="warning">
          <v-icon left>mdi-plus-box-outline</v-icon>
          Create
        </v-btn>
      </v-col>
      
      <!-- LIST OF BENEFICIARIES -->
      <v-col cols="12">

        <SSRTable ref="bnfc-list" :data-url="loadUrl" :data-key="'beneficiaries'" :schema="schema">
          <div slot="actions" slot-scope="{item}">
            <v-btn fab x-small color="warning" @click="showCrudDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </SSRTable>

      </v-col>

    </v-row>

    <!-- DIALOG - CREATE/UPDATE/DELETE BENEFICIARIES -->
    <v-dialog v-model="dialog.crud.show" width="50%">
      <v-card>
        <v-card-title>
          <span v-if="dialog.crud.mode == 'create'"><strong>Create a New Beneficiary</strong></span>
          <span v-else><strong>Update a Beneficiary |&nbsp;</strong>{{ dialog.crud.model.accountName }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="bnfc-form" lazy-validation v-model="dialog.crud.valid">
            <v-row>
              <v-col cols="4">
                <v-autocomplete outlined dense prepend-icon="mdi-bank" label="Bank / Branch"
                  v-model="dialog.crud.model.bankId" :items="banks"
                  item-value="bank_id"
                  @change="onBankSelectionChanged">
                </v-autocomplete>
              </v-col>
              <v-col cols="8"></v-col>

              <v-col cols="4">
                <v-text-field label="Account Name" v-model="dialog.crud.model.accountName"
                  required :rules="dialog.crud.rules.accountName">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Account ID" v-model="dialog.crud.model.accountId"
                  required :rules="dialog.crud.rules.accountId">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Account Number" v-model="dialog.crud.model.accountNo"
                  required :rules="dialog.crud.rules.accountNo">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialog.crud.show = false">Cancel</v-btn>
          <v-btn color="amber" @click="createBeneficiary" v-if="dialog.crud.mode == 'create'">Create</v-btn>
          <div v-else>
            <v-btn color="error" @click="deleteBeneficiary">Delete</v-btn>
            <v-btn color="amber" @click="updateBeneficiary">Update</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';

  import axios from 'axios';

  export default {
    components: {
      SSRTable
    },

    mounted() {
      this.fetchBanks();
    },
    
    methods: {

      fetchBanks() {
        axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/banks`)
        .then(response => {
          this.banks = response.data;
          this.banks.forEach(bank => {
            bank['text'] = `${bank['bank_id']} - ${bank['bank_name']}`;
          })
        });
      },

      onBankSelectionChanged() {
        let bank = this.banks.find(b => {
          return b['bank_id'] === this.dialog.crud.model.bankId;
        });
        console.log(`[DEBUG] onBankSelectionChanged`, this.dialog.crud.model.bankId, bank['bank_name']);
      },

      /// Methods for the dialog of creating/updating beneficiaries
      showCrudDialog(beneficiary) {
        // Update/Delete
        if (beneficiary) {
          this.dialog.crud.mode = 'update';
          this.dialog.crud.beneficiaryId = beneficiary['id'];
          this.dialog.crud.model = {
            bankId: beneficiary['bank_id'],
            accountName: beneficiary['account_name'], accountId: beneficiary['account_id'],
            accountNo: beneficiary['account_no']
          }
        }
        // Create
        else {
          this.dialog.crud.mode = 'create';
          this.dialog.crud.model = {
            bankName: '', bankId: '',
            accountName: '', accountId: '', accountNo: ''
          }
        }

        this.dialog.crud.show = true;
      },
      createBeneficiary() {
        if (!this.$refs['bnfc-form'].validate()) return;

        this.dialog.crud.isProcessing = true;

        // Get bank name.
        let bankId = this.dialog.crud.model.bankId;
        let bank = this.banks.find(b => {
          return bankId === b['bank_id'];
        });  

        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/beneficiaries`,
          {
            "bankId": bankId, "bankName": bank['bank_name'],
            "accountName": this.dialog.crud.model.accountName,
            "accountId": this.dialog.crud.model.accountId,
            "accountNo": this.dialog.crud.model.accountNo
          }
        )
        .then(response => {
          this.$refs['bnfc-list'].loadData();
        })
        .catch(err => {
          console.error(`Failed to create a new beneficiary`, err);
        })
        .finally(() => {
          this.dialog.crud.isProcessing = false;
          this.dialog.crud.show = false;
        });
      },
      updateBeneficiary() {
        this.dialog.crud.isProcessing = true;
        axios.put(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/beneficiaries/${this.dialog.crud.beneficiaryId}`,
          this.dialog.crud.model
        )
        .then(response => {
          this.$refs['bnfc-list'].loadData();
        })
        .catch(err => {
          console.error(`Failed to update a beneficiary`, err);
        })
        .finally(() => {
          this.dialog.crud.isProcessing = false;
          this.dialog.crud.show = false;
        });
      },
      deleteBeneficiary() {
        alert(`Deleting ${this.dialog.crud.model.accountName}. Are you sure?`)
        this.dialog.crud.isProcessing = true;
        axios.delete(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/beneficiaries/${this.dialog.crud.beneficiaryId}`,
        )
        .then(response => {
          this.$refs['bnfc-list'].loadData();
        })
        .catch(err => {
          console.error(`Failed to delete a beneficiary`, err);
        })
        .finally(() => {
          this.dialog.crud.isProcessing = false;
          this.dialog.crud.show = false;
        });
      },
    },

    data() {
      return {
        schema: [
          { value: 'account_name', text: 'Account', attrs: { } },
          { value: 'account_id', text: 'Account ID', attrs: { } },
          { value: 'bank_name', text: 'Bank', attrs: { } },
          { value: 'bank_id', text: 'Bank ID', attrs: { } },
          { value: 'account_no', text: 'Account Number', attrs: { } },
          { value: 'actions', text: 'Actions' }
        ],
        banks: [],

        dialog: {
          crud: {
            show: false,
            mode: 'create',
            beneficiaryId: 0,
            model: {
              bankId: '', accountName: '', accountId: '', accountNo: ''
            },
            isProcessing: false,
            valid: true,
            rules: {
              bankId: [
                v => (v && v.length >= 3) || 'Bank ID must be at least 3-digit long.'
              ],
              accountName: [
                v => (v && v.length >= 2) || 'Account Name must be at least 2 characters.'
              ],
              accountId: [
                v => (v && v.length >= 8) || 'Account ID must be at least 8-character long.'
              ],
              accountNo: [
                v => (v && v.length >= 14) || 'Account Number must be at least 14-digit long.'
              ]
            }
          }
        }
      };
    },

    computed: {
      orgAlias() {
        return this.$store.getters.user['organization']['alias'];
      },

      loadUrl() {
        let orgAlias = this.$store.getters.user['organization']['alias'];
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${orgAlias}/beneficiaries`
          + `?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`;
      }
    }
  }
</script>
